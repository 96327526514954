import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  createStyles,
  makeStyles,
  Theme,
  Box,
  Button,
  Chip,
  Hidden,
  Typography,
  CircularProgress
} from '@material-ui/core'

import { IFileElement } from '../../models/settingsInterfaces'
import SimpleInputBase from './SimpleInputBase'
import { Logger } from '../../utils/log'

interface IFileElementProps {
  element: IFileElement
  fieldKey: string
  baseId?: string
  values?: string
  updateData?: (fieldKey: string, data: any) => void
  deleteFile?: (fieldKey: string, imageName: string) => void
  displayError?: (errorMsg: string) => void
}

const serverHasFile = (values: any) => !!values && values !== 'null' && values !== 'undefined'

/** observer-Component */
/**
 * @param element - Object containing the properties of the input element.
 * For configurator settings, this data comes from the configuration file.
 * @param fieldKey - For configurator settings, this is an identifier for
 * the setting. It is also used as an id for the text field.
 * @param baseId - Optional separate base for the ids of inputs. If not
 * supplied, fieldKey will be used. This might be necessary if the fielKey is not
 * unique across the page.
 * @param values - The data that can be edited in this input
 * @param updateData - 'OnChange' callback for updating data. Will be passed
 * the fieldKey and currently selected file.
 * @param deleteFile - Callback that gets executed when user deletes the file.
 * Will be passed the fieldKey and the name of the currently selected file.
 * @param displayError - Function that display an error message to the user
 */
const FileElement: React.FC<IFileElementProps> = ({
  element,
  fieldKey,
  baseId,
  values,
  updateData,
  deleteFile,
  displayError
}: IFileElementProps) => {
  const classes = useStyles()
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>(
    values ? `${process.env.REACT_APP_UPLOADS_BASE_URL}/${values}` : ''
  )
  const [hasFile, setHasFile] = useState(serverHasFile(values) || !!selectedFile)
  const [loading, setLoading] = useState(false)

  if (!baseId) {
    baseId = fieldKey
  }

  const filename = selectedFile
    ? selectedFile.name
    : values && typeof values === 'string'
    ? values
    : ''
  const acceptedTypes = getAcceptedTypes(element.subtype)

  // Change selectedFileUrl if the data from the store changes
  useEffect(() => {
    if (!selectedFile) {
      setSelectedFileUrl(values ? `${process.env.REACT_APP_UPLOADS_BASE_URL}/${values}` : '')
    }
  }, [values])

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event?.target?.files && event.target.files[0]) {
      setLoading(true)

      setSelectedFile(event.target.files[0])
      setHasFile(true)

      const fr = new FileReader()
      fr.onload = () => {
        if (typeof fr.result === 'string') {
          setSelectedFileUrl(fr.result)
          setLoading(false)
        } else {
          console.warn('Received Array Buffer. No URL to display.')
          setSelectedFileUrl(null)
          setLoading(false)
        }
      }
      fr.onerror = () => {
        Logger.log(fr.error)
        if (displayError) {
          displayError('Fehler beim Hochladen.')
        }
        setLoading(false)
      }
      fr.readAsDataURL(event.target.files[0] as File)

      if (updateData) {
        updateData(fieldKey, event.target.files[0])
      }
    }

    event.target.value = ''
  }

  async function handleDelete() {
    if (serverHasFile(values)) {
      const imgName = values || ''
      if (deleteFile) {
        await deleteFile(fieldKey, imgName)
      }

      setSelectedFile(null)
      setSelectedFileUrl(null)
      setHasFile(false)
    }
  }

  return (
    <SimpleInputBase
      fullWidth={element.fullWidth || false}
      firstRowTitle={element.title || 'File hochladen'}
      firstRowId={`fileInp_${baseId}`}
      toolTip={element.toolTip}
      firstRowChildren={
        <>
          <Box display='flex' alignItems='center'>
            <input
              type='file'
              accept={element.subtype ? getAcceptedTypes(element.subtype) : ''}
              className={classes.fileInput}
              id={`fileInp_${baseId}`}
              onChange={handleChange}
            />
            <label htmlFor={`fileInp_${baseId}`}>
              <Button
                className={classes.uploadBtn}
                variant='contained'
                color='primary'
                component='span'
                disabled={loading}>
                <Hidden xsDown>Datei</Hidden> Wählen
              </Button>
            </label>
            {loading && (
              <Box mb={1} display='flex' alignItems='center'>
                <CircularProgress size={30} />
              </Box>
            )}
            {!loading && hasFile && (
              <Chip
                label={filename}
                aria-label='Datei löschen'
                className={classes.fileNameChip}
                variant='outlined'
                onDelete={handleDelete}
              />
            )}
            {!loading && !hasFile && (
              <Chip
                label='Keine Datei gewählt'
                className={classes.fileNameChip}
                variant='outlined'
              />
            )}
          </Box>
          {!!acceptedTypes?.length && (
            <Typography variant='body1'>{`Zulässige Formate: ${acceptedTypes}`}</Typography>
          )}

          {selectedFile && (
            <Typography variant='body1'>
              Bitte klicken Sie auf &quot;<Hidden xsDown>Änderungen </Hidden>
              Speichern&quot; (oben) um die Datei hochzuladen.
            </Typography>
          )}
        </>
      }
      secondRowChildren={
        element.subtype === 'img' && hasFile ? (
          <img
            src={selectedFileUrl as string}
            className={classes.imgPreview}
            alt='ausgewähltes Bild'
          />
        ) : element.subtype === 'video' && hasFile ? (
          <video controls src={selectedFileUrl as string} className={classes.imgPreview}>
            Vorschau nicht möglich
          </video>
        ) : undefined
      }
    />
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileInput: {
      display: 'none'
    },
    fileNameChip: {
      marginBottom: theme.spacing(1),
      maxWidth: '100%',
      overflowX: 'hidden'
    },
    imgPreview: {
      maxWidth: '800px',
      width: '100%'
    },
    uploadBtn: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1)
    }
  })
)

function getAcceptedTypes(subtype: string): string {
  switch (subtype) {
    case 'img':
      return '.png, .jpeg, .jpg, .svg'
    case 'video':
      return '.mp4, .m4v'
    case 'font':
      return '.ttf, .woff, .otf, .woff2'
    case 'archive':
      return '.tar, .gz'
    case 'all':
      return ''
    default:
      return ''
  }
}

export default observer(FileElement)
