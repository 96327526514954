import React, { useEffect, useState } from 'react'
import {
  createStyles,
  makeStyles,
  Theme,
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Link
} from '@material-ui/core'

import PageTitle from '../components/atoms/PageTitle'
import { IFileElement } from '../models/settingsInterfaces'
import { useStore } from '../stores/StoreContext'
import onSaveNotifier from '../utils/OnSaveNotifier'
import { observer } from 'mobx-react-lite'
import CenteredSpinner from '../components/fallbacks/CenteredSpinner'
import FileElement from '../components/inputElements/FileElement'

/** observer-Component */
const Hidden: React.FC = () => {
  const classes = useStyles()
  const { hiddenStore, uiStore } = useStore()
  const backupFileIdConfigurator = 'hidden_upload_config'
  const backupFileIdCMS = 'hidden_upload_cms'
  const backupFileIdDB = 'hidden_upload_db'
  const [selectedFileConfigurator, setSelectedFileConfigurator] = useState<File | null>(null)
  const [selectedFileCMS, setSelectedFileCMS] = useState<File | null>(null)
  const [selectedFileDB, setSelectedFileDB] = useState<File | null>(null)

  const elementConfigurator: IFileElement = {
    type: 'upload',
    postfix: '',
    id: backupFileIdConfigurator,
    title: 'Konfigurator Backup wiederherstellen',
    show: true,
    subtype: 'archive'
  }

  const elementCMS: IFileElement = {
    type: 'upload',
    postfix: '',
    id: backupFileIdCMS,
    title: 'CMS Backup wiederherstellen',
    show: true,
    subtype: 'archive'
  }

  const elementDB: IFileElement = {
    type: 'upload',
    postfix: '',
    id: backupFileIdDB,
    title: 'Gesamt-Backup wiederherstellen',
    show: true,
    subtype: 'archive'
  }

  function updateFileCallbackConfigurator(_key: string, data: any) {
    setSelectedFileConfigurator(data as File)
  }

  function updateFileCallbackCMS(_key: string, data: any) {
    setSelectedFileCMS(data as File)
  }

  function updateFileCallbackDB(_key: string, data: any) {
    setSelectedFileDB(data as File)
  }

  function dropConfigurator() {
    uiStore.openConfirmDialog({
      title: 'Alle Konfigurator Collections löschen?',
      onConfirm: hiddenStore.dropConfigCollections
    })
  }

  function dropCMS() {
    uiStore.openConfirmDialog({
      title: 'Alle CMS Collections löschen?',
      onConfirm: hiddenStore.dropCMSCollections
    })
  }

  /* Send data to store once 'save' event is fired */
  async function onSave() {
    // const promises: Promise<void>[] = []

    if (selectedFileConfigurator) {
      const data = {
        [backupFileIdConfigurator]: selectedFileConfigurator
      }
      // promises.push(hiddenStore.restoreBackup(data, 'configurator'))
      await hiddenStore.restoreBackup(data, 'configurator')
      setSelectedFileConfigurator(null)
    }

    if (selectedFileCMS) {
      const data = {
        [backupFileIdCMS]: selectedFileCMS
      }
      // promises.push(hiddenStore.restoreBackup(data, 'cms'))
      await hiddenStore.restoreBackup(data, 'cms')
      setSelectedFileCMS(null)
    }

    if (selectedFileDB) {
      const data = {
        [backupFileIdDB]: selectedFileDB
      }
      // promises.push(hiddenStore.restoreBackup(data, 'db'))
      await hiddenStore.restoreBackup(data, 'db')
      setSelectedFileDB(null)
    }

    // Cannot use Promise.all
    // await Promise.allSettled(promises)
  }

  useEffect(() => {
    onSaveNotifier.subscribe(onSave)

    return () => {
      onSaveNotifier.unsubscribe(onSave)
    }
  }, [onSave])

  return (
    <>
      {hiddenStore.isLoading ? (
        <CenteredSpinner />
      ) : (
        <>
          <PageTitle>Backup/Restore</PageTitle>
          <Card className={classes.mb_3}>
            <CardContent>
              <Typography variant='h4' component='h2'>
                Backup wiederherstellen
              </Typography>
              <Card className={classes.inputsContainer}>
                <CardContent>
                  <FileElement
                    element={elementConfigurator}
                    fieldKey={'uploadConfigBackup'}
                    updateData={updateFileCallbackConfigurator}
                  />
                </CardContent>
              </Card>

              <Card className={classes.inputsContainer}>
                <CardContent>
                  <FileElement
                    element={elementCMS}
                    fieldKey={'uploadCMSBackup'}
                    updateData={updateFileCallbackCMS}
                  />
                </CardContent>
              </Card>

              <Card className={classes.inputsContainer}>
                <CardContent>
                  {' '}
                  <FileElement
                    element={elementDB}
                    fieldKey={'uploadDBBackup'}
                    updateData={updateFileCallbackDB}
                  />
                </CardContent>
              </Card>
            </CardContent>
          </Card>

          <Card className={classes.mb_3}>
            <CardContent>
              <Typography variant='h4' component='h2'>
                Backup erstellen
              </Typography>

              <Box display='flex' flexWrap='wrap' p={2}>
                <Button
                  classes={{
                    root: classes.cardBtn
                  }}
                  variant='contained'
                  color='primary'
                  onClick={() => hiddenStore.createBackup('configurator')}>
                  Konfigurator Backup
                </Button>

                <Button
                  classes={{
                    root: classes.cardBtn
                  }}
                  variant='contained'
                  color='primary'
                  onClick={() => hiddenStore.createBackup('cms')}>
                  CMS Backup
                </Button>

                <Button
                  classes={{
                    root: classes.cardBtn
                  }}
                  variant='contained'
                  color='primary'
                  onClick={() => hiddenStore.createBackup('all')}>
                  Gesamt Backup
                </Button>

                <Button
                  classes={{
                    root: classes.cardBtn
                  }}
                  variant='contained'
                  color='primary'
                  onClick={hiddenStore.cleanup}>
                  Cleanup
                </Button>
              </Box>
              {hiddenStore.downloadUrl && (
                <Box display='flex' flexWrap='wrap' px={2} py={1}>
                  <Link href={hiddenStore.downloadUrl} variant='h6'>
                    Download Backup
                  </Link>
                </Box>
              )}
            </CardContent>
          </Card>

          <Card className={classes.mb_3}>
            <CardContent>
              <Typography variant='h4' component='h2'>
                Datenbank
              </Typography>

              <Box display='flex' flexWrap='wrap' p={2}>
                <Button
                  classes={{
                    root: classes.cardBtn
                  }}
                  variant='contained'
                  color='primary'
                  onClick={() => dropConfigurator()}>
                  Drop Konfigurator Collections
                </Button>

                <Button
                  classes={{
                    root: classes.cardBtn
                  }}
                  variant='contained'
                  color='primary'
                  onClick={() => dropCMS()}>
                  Drop CMS Collections
                </Button>
              </Box>
            </CardContent>
          </Card>

          <Card className={classes.mb_3}>
            <CardContent>
              <Typography variant='h4' component='h2'>
                Thumbnails
              </Typography>

              <Box display='flex' flexWrap='wrap' p={2}>
                <Button
                  classes={{
                    root: classes.cardBtn
                  }}
                  variant='contained'
                  color='primary'
                  onClick={() => hiddenStore.regenerateThumbnails()}>
                  Thumbnails erstellen
                </Button>
              </Box>
            </CardContent>
          </Card>

          <Card className={classes.mb_3}>
            <CardContent>
              <Typography variant='h4' component='h2'>
                REMOVE ME!!!
              </Typography>

              <Box display='flex' flexWrap='wrap' p={2}>
                <Button
                  classes={{
                    root: classes.cardBtn
                  }}
                  variant='contained'
                  color='primary'
                  onClick={() => hiddenStore.deleteUploads()}>
                  Uploads löschen
                </Button>
              </Box>
            </CardContent>
          </Card>

          <Card className={classes.mb_3}>
            <CardContent>
              <Typography variant='h4' component='h2'>
                Credentials
              </Typography>
              <Box display='flex' flexWrap='wrap' p={2}>
                <Button
                  classes={{
                    root: classes.cardBtn
                  }}
                  variant='contained'
                  color='primary'
                  onClick={hiddenStore.resetSupport}>
                  Reset support creds
                </Button>
                <Button
                  classes={{
                    root: classes.cardBtn
                  }}
                  variant='contained'
                  color='primary'
                  onClick={hiddenStore.resetAdmin}>
                  Reset admin creds
                </Button>
              </Box>
            </CardContent>
          </Card>
        </>
      )}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardBtn: {
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        flexBasis: '100%'
      }
    },
    inputsContainer: {
      backgroundColor: theme.custom.defaultBackgroundColor,
      margin: `${theme.spacing(2)}px 0`,
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(2)
      }
    },
    mb_3: {
      marginBottom: theme.spacing(3)
    }
  })
)

export default observer(Hidden)
