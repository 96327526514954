import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  createStyles,
  makeStyles,
  Theme,
  Box,
  Button,
  Chip,
  Hidden,
  Typography,
  CircularProgress,
  Avatar
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import LazyLoad from 'react-lazyload'

import { IFileElement } from '../../models/settingsInterfaces'
import SimpleInputBase from './SimpleInputBase'
import { FileLibraryListItem } from '../../mediaLibrary/types'

interface IFileElementProps {
  element: IFileElement
  fieldKey: string
  baseId?: string
  values?: string
  updateData?: (fieldKey: string, data: any) => void
  onFileSelectRequest?: (
    onSelect: (item: FileLibraryListItem) => void,
    acceptedTypes: string
  ) => void
  checkIfFileExists?: (filename: string) => boolean
}

const storeHasFile = (values: any) => !!values && values !== 'null' && values !== 'undefined'

const ImagePlaceholder: React.FC = () => {
  const classes = usePlaceholderStyles()
  return <Skeleton variant='rect' height={250} className={classes.imglaceholder} />
}

/** observer-Component */
/**
 * @param element - Object containing the properties of the input element.
 * For configurator settings, this data comes from the configuration file.
 * @param fieldKey - For configurator settings, this is an identifier for
 * the setting. It is also used as an id for the text field.
 * @param baseId - Optional separate base for the ids of inputs. If not
 * supplied, fieldKey will be used. This might be necessary if the fielKey is not
 * unique across the page.
 * @param values - The data that can be edited in this input
 * @param updateData - 'OnChange' callback for updating data. Will be passed
 * the fieldKey and currently selected file.
 * @param onFileSelectRequest - Callback that gets executed when user clicks the
 * select-file button. This can be used to open a file-browser UI. Receives a
 * callback function that gets executed when a file is selected and string containing
 * the accepted types.
 * @param checkIfFileExists - Function that checks if a file is available on
 * the server. Receives a file name.
 */
const FileElement: React.FC<IFileElementProps> = ({
  element,
  fieldKey,
  baseId,
  values,
  updateData,
  onFileSelectRequest,
  checkIfFileExists
}: IFileElementProps) => {
  let serverHasFile: boolean | undefined = undefined

  const selectedFileUrl = values ? `${process.env.REACT_APP_UPLOADS_BASE_URL}/${values}` : ''

  if (selectedFileUrl) {
    serverHasFile = checkIfFileExists
      ? checkIfFileExists(selectedFileUrl.replace(/^.*[\\\/]/, ''))
      : false
  }

  const hasFile = storeHasFile(values) || !!selectedFileUrl
  const [loading, setLoading] = useState(false)
  const [mediaLoading, setMediaLoading] = useState(false)
  const classes = useStyles({ showImg: !mediaLoading })

  if (!baseId) {
    baseId = fieldKey
  }

  const filename = selectedFileUrl ? selectedFileUrl.replace(/^.*(\\|\/|\:)/, '') : ''
  const acceptedTypes = getAcceptedTypes(element.subtype)

  function handleSelectRequest() {
    if (onFileSelectRequest) {
      onFileSelectRequest(handleChange, acceptedTypes)
    }
  }

  function handleChange(file: FileLibraryListItem) {
    setLoading(true)

    if (updateData) {
      updateData(fieldKey, file.filePath)
    }
    setLoading(false)
  }

  async function handleDelete() {
    setLoading(false)

    if (updateData) {
      updateData(fieldKey, '')
    }
  }

  return (
    <SimpleInputBase
      fullWidth={element.fullWidth || false}
      firstRowTitle={element.title || 'File hochladen'}
      firstRowId={`fileInp_${baseId}`}
      toolTip={element.toolTip}
      firstRowChildren={
        <>
          <Box display='flex' alignItems='center'>
            <Button
              className={classes.uploadBtn}
              variant='contained'
              color='primary'
              disabled={loading}
              onClick={handleSelectRequest}>
              <Hidden xsDown>Datei</Hidden> Wählen
            </Button>

            {loading && (
              <Box mb={1} display='flex' alignItems='center'>
                <CircularProgress size={30} />
              </Box>
            )}
            {!loading && hasFile && (
              <Chip
                label={filename}
                color={serverHasFile === false ? 'secondary' : 'default'}
                avatar={serverHasFile === false ? <Avatar>Gelöscht</Avatar> : undefined}
                aria-label='Datei löschen'
                classes={{
                  root: classes.fileNameChip,
                  avatar: classes.deletedAvatar,
                  avatarColorSecondary: classes.deletedAvatar
                }}
                variant='outlined'
                onDelete={handleDelete}
              />
            )}
            {!loading && !hasFile && (
              <Chip
                label='Keine Datei gewählt'
                classes={{ root: classes.fileNameChip }}
                variant='outlined'
              />
            )}
          </Box>
          {!!acceptedTypes?.length && (
            <Typography variant='body1'>{`Zulässige Formate: ${acceptedTypes}`}</Typography>
          )}
        </>
      }
      secondRowChildren={
        element.subtype === 'img' && serverHasFile ? (
          <>
            {/* {mediaLoading && <ImagePlaceholder />} */}
            <LazyLoad height={280} offset={100} placeholder={<ImagePlaceholder />}>
              <img
                src={selectedFileUrl as string}
                className={classes.imgPreview}
                alt='ausgewähltes Bild'
                onLoad={() => setMediaLoading(false)}
              />
            </LazyLoad>
          </>
        ) : element.subtype === 'video' && hasFile ? (
          // <LazyLoad height={280} offset={100} placeholder={<ImagePlaceholder />}>
          <video controls src={selectedFileUrl as string} className={classes.imgPreview}>
            Vorschau nicht möglich
          </video>
        ) : // </LazyLoad>
        undefined
      }
    />
  )
}

interface IStyleProps {
  showImg: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deletedAvatar: {
      width: 'min-content',
      fallbacks: {
        width: '60px'
      },
      paddingLeft: '6px',
      paddingRight: '6px',
      borderRadius: '16px'
    },
    fileInput: {
      display: 'none'
    },
    fileNameChip: {
      marginBottom: theme.spacing(1),
      maxWidth: '100%',
      overflowX: 'hidden',
      '& .MuiChip-avatarColorSecondary': {
        width: 'min-content',
        fallbacks: {
          width: '60px'
        }
      }
    },
    imgPreview: {
      display: (props: IStyleProps) => (props.showImg ? 'initial' : 'none'),
      maxWidth: '800px',
      width: '100%'
    },
    uploadBtn: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1)
    }
  })
)

const usePlaceholderStyles = makeStyles(
  createStyles({
    imglaceholder: {
      maxWidth: '800px',
      width: '100%'
    }
  })
)

function getAcceptedTypes(subtype: string): string {
  switch (subtype) {
    case 'img':
      return '.png, .jpeg, .jpg, .svg'
    // return 'image/*'
    case 'video':
      return '.mp4, .m4v'
    case 'font':
      return '.ttf, .woff, .otf, .woff2'
    case 'archive':
      return '.tar, .gz'
    case 'all':
      return ''
    default:
      return ''
  }
}

export default observer(FileElement)
